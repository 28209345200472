// contexts/LocalStorageContext.tsx
import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from "react";

interface LocalStorageContextType {
  admin: boolean;
  staff: boolean;
  manager: boolean;
  orgId: string;
  crowdId: string;
  allMembersId: string;
  windowSize: Array<number>; // Assuming size is an array of numbers
  isMobile: boolean;
  fullName: string;
  email: string;
  userId: string;
}

const LocalStorageContext = createContext<LocalStorageContextType | undefined>(
  undefined
);

const getLocalStorage = () => (typeof window !== 'undefined' ? window.localStorage : undefined);

export const useLocalStorage = () => {
  const context = useContext(LocalStorageContext);
  if (context === undefined) {
    throw new Error(
      "useLocalStorage must be used within a LocalStorageProvider"
    );
  }
  return context;
};

export const LocalStorageProvider = ({ children }: { children: ReactNode }) => {
  const localStorage = getLocalStorage;
  // add all the states you want to access globally
  const admin = localStorage()?.admin === "true" ? true : false;
  const staff = localStorage()?.staff === "true" ? true : false;
  const manager = localStorage()?.manager === "true" ? true : false;
  const orgId = localStorage()?.orgSelected;
  const crowdId = localStorage()?.crowdSelected;
  const allMembersId = localStorage()?.allMembersId;

  function useWindowSize() {
    const isClient = typeof window === "object";

    const [size, setSize] = useState(
      isClient ? [window.innerWidth, window.innerHeight] : [0, 0]
    );

    useEffect(() => {
      if (!isClient) {
        return;
      }

      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => {
        window.removeEventListener("resize", updateSize);
      };
    }, [isClient]);

    return size;
  }

  const windowSize = useWindowSize();
  const isMobile = windowSize[0] < 900;

  // then pass them to the value prop
  return (
    <LocalStorageContext.Provider
      value={{
        admin,
        staff,
        manager,
        orgId,
        crowdId,
        allMembersId,
        windowSize,
        isMobile,
        fullName: localStorage()?.fullName,
        email: localStorage()?.email,
        userId: localStorage()?.userId,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};
